import React from 'react';
import '../../../css/style.css';

function Rodape(){
    return(
<div className="rodape">
    <p className="p-rod">
        Copyright 2023 © Todos os direitos reservados
    </p>
</div>
    )
}
export default Rodape;