import React from 'react';
import '../css/style.css';
function Espaco (){
    return(
        <div className="espaco
        ">

        </div>
    )
}
export default Espaco