import React from 'react';
import '../../../css/style.css';
import Sobrenos from './sobrenos';
import Rodape from './rodape';
function Info (){
    return(
        <div>
        <Sobrenos/>
        <Rodape/>
        </div>
    )
}
export default Info;